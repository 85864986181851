import { purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { useOrderIncomingLogic } from "@/features/order/hook/useOrderIncoming";
import { DeliveryTable } from "@/features/order/table/deliveryTable";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { LotForm } from "@/features/ui/form/lots/form";
import styled from "@emotion/styled";
import { Button, Flex, Space, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { PurchaseOrdersGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { EventHandler, useEffect, useState } from "react";

interface PurchaseOrderItemsSelectFormProps {
    formatterProps: PurchaseOrdersGet200ResponseRowsInner | undefined
}

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const OrderItemsIncomingForm = (params: PurchaseOrderItemsSelectFormProps) => {
    const { formatterProps } = params;
    const { closeModal, openModal } = useModal();
    const [selectedRadio, setSelectedRadio] = useState(null);

    const { data: purchaseItemData } = useQuery({
        ...purchaseOrderItems.detail({
            purchaseOrderItemId: selectedRadio ?? 0,
        }),
        enabled: !!selectedRadio,
    })

    const { form, onSubmit, onAddNewLot, newLot, itemData, lotData, LoadingOverlay, loading } = useOrderIncomingLogic(purchaseItemData?.data, formatterProps);

    const handleRadioChange = (id: number | any) => {
        setSelectedRadio(id);
    };

    const onCloseHandler: EventHandler<any> = (values) => {
        onAddNewLot(values)
        closeModal(values);
    };

    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <LotForm
                itemCodeValue={purchaseItemData?.data?.itemCode as string}
                onSubmit={onCloseHandler}
            />,
            null,
            "신규 LOT 등록",
            true
        ).then((result) => { });
    };

    const { itemCode } = form.values;
    const { rows } = itemData?.data || {};
    const usesLotName = rows?.[0]?.usesLotName;
    const usesLotExpiration = rows?.[0]?.usesLotExpiration;

    useEffect(() => {
        if (selectedRadio && purchaseItemData?.data?.itemCode) {
            form.setFieldValue("itemCode", purchaseItemData.data.itemCode);
        }
    }, [selectedRadio, purchaseItemData]);

    // 조건에 따른 LOT 관리 기준 설명 설정
    const lotDescription = !itemCode
        ? "관리 안함"
        : usesLotName && usesLotExpiration
            ? "이름 + 만료일"
            : usesLotName
                ? "이름"
                : usesLotExpiration
                    ? "만료일"
                    : "관리 안함";


    return (
        <>
            <Wrapper>
                {loading && <LoadingOverlay />}
                {formatterProps?.purchaseOrderItems && formatterProps.purchaseOrderItems.length > 0 && (
                    formatterProps.purchaseOrderItems.map((items) => (
                        <DeliveryTable
                            id={items.id}
                            partnerCode={items.partnerCode}
                            itemCode={items.itemCode}
                            quantity={items.quantity}
                            locationCode={items.targetLocationCode}
                            selectedRadio={selectedRadio}
                            onChange={handleRadioChange}
                            inputQuantity={items.inputQuantity}
                        />
                    ))
                )}
                {formatterProps?.purchaseOrderItems && formatterProps.purchaseOrderItems.length === 0 ? (
                    <Flex>
                        <Text size="xl" fw="bold">
                            발주항목이 없습니다. 발주항목을 생성해주세요.
                        </Text>
                    </Flex>
                ) : (
                    <>
                        <Create.Input
                            disabled
                            label="품목코드"
                            withAsterisk
                            {...form.getInputProps("itemCode")}
                            defaultValue={purchaseItemData?.data?.itemCode}
                        />
                        <Flex justify={"space-between"} align={'center'} w="100%">
                            <LotSelectComplete
                                w="100%"
                                label="LOT 정보"
                                description={`LOT 관리 기준 : ${lotDescription}`}
                                itemCodeValue={purchaseItemData?.data?.itemCode as string}
                                withAsterisk
                                newIdValue={lotData?.id ?? newLot?.id}
                                dropdownPosition="bottom"
                                {...form.getInputProps("lotId")}
                                defaultValue={lotData && lotData?.id?.toString()}
                                mr="sm"
                                disabled={usesLotName || usesLotExpiration ? false : true}
                                styles={{
                                    description: { fontSize: 10, color: "red" },
                                    input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                                }}
                            />
                            <div>
                                <Space h="md" />
                                <Space h="xl" />
                                <Button
                                    my="xl"
                                    disabled={
                                        usesLotName || usesLotExpiration
                                            ? false
                                            : true
                                    }
                                    onClick={addNewModalHandler}
                                >
                                    신규로트 생성
                                </Button>
                            </div>
                        </Flex>
                        <LocationAutoComplete
                            label="로케이션코드"
                            withAsterisk
                            defaultValue={formatterProps?.targetLocationCode}
                            {...form.getInputProps("locationCode")}
                        />
                        <Create.Input
                            label="수량"
                            withAsterisk
                            type="number"
                            {...form.getInputProps("quantity")}
                        />
                        <Create.Input
                            disabled
                            label="만료일"
                            {...form.getInputProps("lotInfo.expiration")}
                        />
                        <Create.ButtonBox>
                            <Create.Button color="gray" onClick={closeModal}>
                                취소
                            </Create.Button>
                            <Create.Button onClick={onSubmit} disabled={!selectedRadio}>입고</Create.Button>
                        </Create.ButtonBox>
                    </>
                )}
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
