import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";


const KPIDefect = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
})

export const KPIDefectPage = () => {
    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [totalDefectQuantity, setTotalDefectQuantity] = useState<number>(0);
    const [totalDefectRate, setTotalDefectRate] = useState<string>('');

    const { itemData, defectData } = useKPIData({ item, stateDate, options: { fetchDefectRate: true } })

    useEffect(() => {
        if (defectData?.data) {
            const transformedData = defectData.data.map((defect: any) => ({
                id: defect.id,
                productionPlanId: defect.productionPlanId,
                scheduledAt: defect.scheduledAt,
                operationName: defect.operationName,
                equipmentName: defect.equipmentName,
                itemCode: defect.itemCode,
                itemSpec: defect.itemSpec,
                workerName: defect.workerName,
                workEndTime: DatetimeUtilMinute(defect.workEndTime),
                workStartTime: DatetimeUtilMinute(defect.workStartTime),
                pauseDuration: defect.pauseDuration,
                totalQuantity: `${defect.totalQuantity} ${itemData?.unitText}`,
                goodQuantity: `${defect.goodQuantity} ${itemData?.unitText}`,
                defectQuantity: `${defect.defectQuantity} ${itemData?.unitText}`,
                createdAt: defect.createdAt,
                updatedAt: defect.updatedAt,
                defectRate: defect.defectRate,
            }));

            const totalQuantity = defectData?.data?.reduce((sum: number, defect: any) => sum + parseFloat(defect.totalQuantity), 0) ?? 0;
            const totalDefectQuantity = defectData?.data?.reduce((sum: number, defect: any) => sum + parseFloat(defect.defectQuantity), 0) ?? 0;

            // 평균 불량률 계산
            const averageDefectRate = (
                defectData.data.reduce((sum: number, defect: any) => sum + parseFloat(defect.defectRate), 0) / defectData.data.length
            ).toFixed(2);

            setTotalQuantity(totalQuantity);
            setTotalDefectQuantity(totalDefectQuantity);
            setTotalDefectRate(averageDefectRate);

            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [defectData]);


    const TableHeader = {
        machine: ['품목코드', '품명', '규격', '총 생산 수량', '총 불량 수량', '평균 불량률(%)'],
    }

    const BodyData = {
        machine: [{ '품목코드': itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec, '총 생산 수량': totalQuantity, '총 불량 수량': totalDefectQuantity, '평균 불량률(%)': totalDefectRate === "NaN" ? '-' : totalDefectRate }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPIDefect.Header>
                <KPIDefect.HeaderTitle>품질 불량률(Q)</KPIDefect.HeaderTitle>
                <KPIDefect.HeaderSubTitle>불량수량 / 총 생산수량</KPIDefect.HeaderSubTitle>
            </KPIDefect.Header>
            <KPIDefect.KPIActionHeader setItem={setItem} stateDate={stateDate} setStateDate={setStateDate} />

            <KPIDefect.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.defectTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            />
        </Flex>
    )
}