import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { useModal } from "@/features/modal/ModalStackManager";
import { useOrderIncomingLogic } from "@/features/order/hook/useOrderIncoming";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotSelectComplete } from "@/features/ui/autoComplete/lot/lot-\bselectComplete";
import { LotForm } from "@/features/ui/form/lots/form";
import { Button, Flex, Space, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem } from "@sizlcorp/sizl-api-document/dist/models";
import { EventHandler } from "react";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const DeliveryIncomingForm = (params: { formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem }) => {
    const { closeModal, openModal } = useModal();
    const { formatterProps } = params;

    const { form, onSubmit, onAddNewLot, newLot, itemData, lotData, loading, LoadingOverlay } = useOrderIncomingLogic(formatterProps);

    const onCloseHandler: EventHandler<any> = (values) => {
        onAddNewLot(values);
        closeModal(values);
    };

    const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
        e
    ) => {
        e.preventDefault();
        openModal(
            <LotForm
                itemCodeValue={form.values.itemCode as string}
                onSubmit={onCloseHandler}
            />,
            null,
            "신규 LOT 등록",
            true
        ).then((result) => { });
    };

    const { itemCode } = form.values;
    const { rows } = itemData?.data || {};
    const usesLotName = rows?.[0]?.usesLotName;
    const usesLotExpiration = rows?.[0]?.usesLotExpiration;

    // 조건에 따른 LOT 관리 기준 설명 설정
    const lotDescription = !itemCode
        ? "관리 안함"
        : usesLotName && usesLotExpiration
            ? "이름 + 만료일"
            : usesLotName
                ? "이름"
                : usesLotExpiration
                    ? "만료일"
                    : "관리 안함";

    return (
        <>
            <Create.Wrapper>
                {loading && <LoadingOverlay />}
                <Create.Input
                    disabled
                    label="품목코드"
                    withAsterisk
                    {...form.getInputProps("itemCode")}
                    defaultValue={formatterProps?.itemCode}
                />
                <Flex justify="space-between" align="center" w="100%">
                    <LotSelectComplete
                        w="100%"
                        label="LOT 정보"
                        withAsterisk
                        description={`LOT 관리 기준 : ${lotDescription}`}
                        itemCodeValue={formatterProps.itemCode as string} // keyword
                        newIdValue={lotData?.id ?? newLot?.id}
                        dropdownPosition="bottom"
                        {...form.getInputProps("lotId")}
                        defaultValue={lotData?.id!.toString() ?? newLot?.id!.toString()}
                        mr="sm"
                        disabled={usesLotName || usesLotExpiration ? false : true}
                        styles={{
                            description: { fontSize: 10, color: "red" },
                            input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                        }}
                    />
                    <div>
                        <Space h="xl" />
                        <Space h="lg" />
                        <Button
                            my="xl"
                            disabled={
                                (itemData?.data?.rows &&
                                    itemData?.data?.rows[0]?.usesLotName) ||
                                    (itemData?.data?.rows &&
                                        itemData?.data?.rows[0]?.usesLotExpiration)
                                    ? false
                                    : true
                            }
                            onClick={addNewModalHandler}
                        >
                            신규로트 생성
                        </Button>
                    </div>
                </Flex>
                <LocationAutoComplete
                    label="로케이션코드"
                    withAsterisk
                    {...form.getInputProps("locationCode")}
                />
                <Create.Input
                    label="수량"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("quantity")}
                />
                <Create.Input
                    disabled
                    label="만료일"
                    {...form.getInputProps("lotInfo.expiration")}
                />
                <Create.Input
                    label="수주 정보"
                    {...form.getInputProps("purchaseOrderItemId")}
                    defaultValue={formatterProps?.id}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>
                        취소
                    </Create.Button>
                    <Create.Button onClick={onSubmit}>입고</Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    );
};
