import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import timeUtil from "@/utils/timeUtil";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { usePruchaseOrderOutgingLogic } from "../hook/usePurchaseOrderOutging";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Date: DateInput,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    ItemAutoComplete: ItemAutoComplete,
});

export const DeliveryForm = (params: { formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem }) => {
    const { closeModal } = useModal();
    const { formatterProps } = params;
    const { onSubmit, form, inventoryData, selectLot, setSelectLot } = usePruchaseOrderOutgingLogic(formatterProps)

    return (
        <>
            <Create.Wrapper>
                <Create.Input
                    disabled
                    label="품목코드"
                    withAsterisk
                    {...form.getInputProps("itemCode")}
                    defaultValue={formatterProps?.itemCode}
                />
                <Select
                    label="LOT 정보"
                    clearable
                    data={inventoryData?.data ? inventoryData.data
                        .filter((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item?.quantity && item?.quantity > "0") // 수량이 존재하고 0보다 큰 항목만 필터링
                        .map((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => {
                            const name = item?.lot?.name ?? '-'
                            const expiration = item?.lot?.expiration === null ? '-' : timeUtil(item?.lot?.expiration ?? '')
                            const locationCode = item?.locationCode
                            const quantity = item?.quantity
                            return {
                                value: item.lotId ? item.lotId.toString() : '', // value를 string으로 변환
                                label: `이름 : ${name} 유효기한 : ${(expiration)} 로케이션코드 : ${locationCode} 수량 : ${quantity}`
                            }
                        }) : []}
                    onChange={(e) => {
                        const selectedLotId = Number(e);
                        const matchedItem = inventoryData?.data?.find((item: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner) => item.lotId === selectedLotId);
                        setSelectLot(matchedItem);
                    }}
                />
                <Create.Input
                    disabled
                    label="로케이션코드"
                    withAsterisk
                    {...form.getInputProps("locationCode")}
                    defaultValue={selectLot?.locationCode}
                />
                <Create.Input
                    label="수량"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("quantity")}
                    defaultValue={selectLot?.quantity}
                />
                <Create.Input
                    disabled
                    label="만료일"
                    {...form.getInputProps("lotInfo.expiration")}
                    defaultValue={selectLot?.lot?.expiration}
                />
                <Create.Input
                    label="수주 정보"
                    {...form.getInputProps("purchaseOrderItemId")}
                    defaultValue={formatterProps?.partnerCode}
                />
                {
                    customFunctions.ADD_PURCHASE_ORDER_INVOICE_DATE && (
                        <DateInput
                            label="출하 날짜"
                            valueFormat="YYYY-MM-DD"
                            value={dayjs(form.values.invoiceDate).toDate()}
                            onChange={(e) => {
                                form.setFieldValue('invoiceDate', dayjs(e).startOf('day')?.toISOString());
                            }}
                        />
                    )
                }
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>
                        취소
                    </Create.Button>
                    <Create.Button onClick={onSubmit}>납품</Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    );
};
