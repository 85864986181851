import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPIUPH = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
})

export const KPIUPHPage = () => {

    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [averageUPH, setAverageUPH] = useState<string>('');

    const { itemData, UPHData } = useKPIData({ item, stateDate, options: { fetchUPH: true } })

    useEffect(() => {
        if (UPHData?.data) {
            const transformedData = UPHData.data.map((uph: any) => ({
                id: uph.id,
                productionPlanId: uph.productionPlanId,
                scheduledAt: uph.scheduledAt,
                operationName: uph.operationName,
                equipmentName: uph.equipmentName,
                itemCode: uph.itemCode,
                itemSpec: uph.itemSpec,
                workerName: uph.workerName,
                workStartTime: DatetimeUtilMinute(uph.workStartTime),
                workEndTime: DatetimeUtilMinute(uph.workEndTime),
                pauseDuration: uph.pauseDuration,
                totalQuantity: `${uph.totalQuantity} ${itemData?.unitText}`,
                goodQuantity: `${uph.goodQuantity} ${itemData?.unitText}`,
                defectQuantity: `${uph.defectQuantity} ${itemData?.unitText}`,
                uph: uph.uph,
            }));

            const totalUPH = UPHData?.data?.reduce((sum: number, uph: any) => sum + parseFloat(uph.uph), 0) ?? 0;
            const averageUPH = (totalUPH / UPHData.data.length).toFixed(2);

            setAverageUPH(averageUPH);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [UPHData])

    const TableHeader = {
        machine: ['품목코드', '품명', '규격', '평균 UPH'],
    }

    const BodyData = {
        machine: [{ '품목코드': itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec ?? '-', '평균 UPH': averageUPH === "NaN" ? '0' : averageUPH }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPIUPH.Header>
                <KPIUPH.HeaderTitle>UPH(P)</KPIUPH.HeaderTitle>
                <KPIUPH.HeaderSubTitle>시간당 생산량</KPIUPH.HeaderSubTitle>
            </KPIUPH.Header>
            <KPIUPH.KPIActionHeader setItem={setItem} stateDate={stateDate} setStateDate={setStateDate} />

            <KPIUPH.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.uphTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            />
        </Flex>
    )
}