import { columnlist } from "@/constants/columnlist";
import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const KPIPowerUsage = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
})

export const KPIPowerUsagePage = () => {
    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [averagePowerUsage, setAveragePowerUsage] = useState<string>('');

    const { itemData, powerUsageData } = useKPIData({ item, stateDate, options: { fetchPowerUsage: true } })

    const TableHeader = {
        machine: ['품목코드', '품목명', '규격', '개당 평균 전력 사용량(kWh)'],
    }

    const BodyData = {
        machine: [{ '품목코드': itemData?.code ?? '-', '품목명': itemData?.name, '규격': itemData?.spec, '개당 평균 전력 사용량(kWh)': averagePowerUsage === "NaN" ? '-' : averagePowerUsage }],
    }

    useEffect(() => {
        if (powerUsageData?.data) {
            const transformedData = powerUsageData.data.map((powerUsage: any) => ({
                id: powerUsage.id,
                productionPlanId: powerUsage.productionPlanId,
                operationName: powerUsage.operationName,
                equipmentName: powerUsage.equipmentName,
                equipmentVolt: powerUsage.equipmentVolt,
                itemCode: powerUsage.itemCode,
                itemName: powerUsage.itemName,
                itemSpec: powerUsage.itemSpec,
                workerName: powerUsage.workerName,
                workStartTime: DatetimeUtilMinute(powerUsage.workStartTime),
                workEndTime: DatetimeUtilMinute(powerUsage.workEndTime),
                pauseDuration: powerUsage.pauseDuration,
                totalQuantity: `${powerUsage.totalQuantity} ${itemData?.unitText}`,
                goodQuantity: `${powerUsage.goodQuantity} ${itemData?.unitText}`,
                defectQuantity: `${powerUsage.defectQuantity} ${itemData?.unitText}`,
                electricPower: powerUsage.electricPower,
            }));

            const totalElectricPower = powerUsageData?.data?.reduce((sum: number, powerUsage: any) => sum + parseFloat(powerUsage.electricPower), 0) ?? 0;
            const averageElectricPower = (totalElectricPower / powerUsageData.data.length).toFixed(2);
            setAveragePowerUsage(averageElectricPower);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [powerUsageData]);

    return (
        <Flex gap="md" direction={'column'}>
            <KPIPowerUsage.Header>
                <KPIPowerUsage.HeaderTitle>전력 사용량(E)</KPIPowerUsage.HeaderTitle>
                <KPIPowerUsage.HeaderSubTitle>작업지시 당 소모한 전력량</KPIPowerUsage.HeaderSubTitle>
            </KPIPowerUsage.Header>
            <KPIPowerUsage.KPIActionHeader setItem={setItem} stateDate={stateDate} setStateDate={setStateDate} />

            <KPIPowerUsage.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.powerUsageTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            />
        </Flex>
    )
}