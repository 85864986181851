import { mutateSpecifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { InspectSpecificationCreateFormWithItems } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationCreateFormWithItems";
import { InspectSpecificationUpdateForm } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationUpdateForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ActionIcon, Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import { SpcApiSpecificationsSpecificationIdDeleteRequest, SpecificationsGet200Response, SpecificationsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";


// "우리 회사에서 사용하는 품목의 검사표준을 조회할 수 있는 페이지 입니다."
export const InspectSpecificationPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: SpcApiSpecificationsSpecificationIdDeleteRequest) =>
      mutateSpecifications.delete(params).mutationFn(params as SpcApiSpecificationsSpecificationIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          "검사기준 삭제에 성공하였습니다.",
          "삭제 성공",
          "green"
        );
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: "검사기준 코드" }), "검사기준 삭제 실패", "red");
      }
    }
  );

  const columns: readonly Column<SpecificationsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps: any) => {
        return (
          <LinkAnchor onClick={() => openModal(<ItemsDetailForm itemCode={formatterProps.row.itemCode} />, null, "")}>{formatterProps.row.itemCode}</LinkAnchor>
        );
      },
    },
    {
      key: "routingCode",
      name: "검사종류",
      sortable: true,
      resizable: true,
      formatter: () => {
        return (
          <Badge color="blue" radius={2} fw={700} size={"9px"}>
            초중종물 검사
          </Badge>
        );
      },
    },
    {
      key: "summary",
      name: "검사 기준 요약",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        // criteria가 존재하고 배열인지 확인 후 처리
        const summaryNames = Array.isArray(formatterProps.row.criteria)
          ? formatterProps.row.criteria.map((data) => data.name)
          : [];

        return (
          <Flex>
            {summaryNames.length > 1
              ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
              : summaryNames[0] ?? ""}
          </Flex>
        );
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {

        const updateInspectSpecificationActionView = () => {
          openModal(
            <InspectSpecificationUpdateForm
              data={{
                itemCode: formatterProps.row.itemCode,
              }}
            />,
            "",
            "검사기준 수정",
            true
          )
        }

        const deleteInspectSpecificationAction = () => {
          openModal(
            <ConfirmForm
              message="선택한 행을 삭제하시겠습니까?"
              yesCallback={() => deleteMutate({ specificationId: formatterProps?.row?.id as number })}
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "검사기준 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateInspectSpecificationActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteInspectSpecificationAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 검사기준을 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ specificationId: row });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "검사기준 삭제",
    )
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<InspectSpecificationCreateFormWithItems />, null, "검사기준 추가", true)} createDataTitle="검사기준" deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>검사기준 리스트</Text>
        <Text fz="xs">우리 회사에서 다루는 품목의 검사기준에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<SpecificationsGet200Response, SpecificationsGet200ResponseRowsInner> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<SpecificationsGet200Response> />
      </Flex>
    </Flex>
  );
};
