import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { getUserCodeByUser } from "@/utils/checkData";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

interface PurchaseOrderUpdateFormProps {
    purchaseOrderId?: number;
    formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem
}

export const PurchaseOrderItemsUpdateForm = (
    props: PurchaseOrderUpdateFormProps
) => {
    const { purchaseOrderId, formatterProps } = props;
    const userCode = getUserCodeByUser();
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) =>
            mutatePurchaseOrderItems.update(params).mutationFn(params as ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['purchaseOrderItems']);
                closeModal(form.values);
                customAlert(
                    `${userCode === "PB" ? "발주 항목 수정에 성공하였습니다." : "수주 항목 수정에 성공하였습니다."}`,
                    "수정 성공",
                    "green"
                )
            },
            onError: (error: any) => {
                customAlert(handleErrorResponse({ error, errorContext: `${userCode === 'PB' ? '발주 항목' : '수주 항목'}` }), `${userCode === "PB" ? '발주 항목 수정 실패' : '수주 항목 수정 실패'}`, 'red')
            }
        }
    );

    const form = useForm({
        initialValues: {
            partnerCode: formatterProps.partnerCode,
            itemCode: formatterProps.itemCode,
            quantity: formatterProps.quantity,
            orderDate: formatterProps.orderDate ? new Date(formatterProps.orderDate) : undefined,
            deliveryDeadline: formatterProps.deliveryDeadline ? new Date(formatterProps.deliveryDeadline) : undefined,
            userCode: formatterProps.userCode,
            sourceLocationCode: formatterProps.sourceLocationCode,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    purchaseOrderItemId: formatterProps.id ?? 0,
                    purchaseOrderItemsGetRequest: {
                        purchaseOrderId: formatterProps.purchaseOrderId,
                        partnerCode: form.values.partnerCode!,
                        direction: "INBOUND",
                        itemCode: form.values.itemCode!,
                        quantity: form.values.quantity!,
                        orderDate: form.values.orderDate?.toDateString()!,
                        deliveryDeadline: form.values.deliveryDeadline!?.toDateString(),
                        userCode: form.values.userCode!,
                        sourceLocationCode: form.values.sourceLocationCode!,
                    }
                },
            )
        } catch (e) {
            customAlert(
                `${userCode === "PB" ? "발주 항목 수정에 실패하였습니다." : "수주 항목 수정에 실패하였습니다."}`,
                "수정 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Update.Wrapper>
                <PurchaseOrderAutoComplete
                    label={`${userCode === "PB" ? "발주 코드" : "수주 코드"}`}
                    placeholder={`${userCode === "PB" ? "발주 코드 입력" : "수주 코드 입력"}`}
                    {...form.getInputProps("purchaseOrderId")}
                    defaultValue={formatterProps.purchaseOrderCode}
                    error={form.isDirty("purchaseOrderId") ? "저장 필요" : ""}
                    direction="INBOUND"
                />
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    defaultValue={formatterProps.partnerCode}
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                    error={form.isDirty("partnerCode") ? "저장 필요" : ""}
                />
                <ItemAutoComplete
                    label="품목코드"
                    placeholder="품목 코드 입력"
                    {...form.getInputProps("itemCode")}
                    defaultValue={formatterProps.itemCode}
                    error={form.isDirty("itemCode") ? "저장 필요" : ""}
                />
                <Update.Input
                    label="수량"
                    placeholder="수량"
                    {...form.getInputProps("quantity")}
                    defaultValue={formatterProps.quantity}
                    error={form.isDirty("quantity") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("quantity") ? "red !important" : "",
                            borderColor: form.isDirty("quantity") ? "red !important" : "",
                        },
                        error: { fontSize: 11, color: "red" }
                    }}
                />
                <UserAutoComplete
                    label="담당자"
                    placeholder="담당자 입력"
                    {...form.getInputProps("userCode")}
                    defaultValue={formatterProps.userCode}
                    error={form.isDirty("userCode") ? "저장 필요" : ""}
                />
                <LocationAutoComplete
                    label="출고 로케이션"
                    placeholder="출고 로케이션"
                    {...form.getInputProps("sourceLocationCode")}
                    defaultValue={formatterProps.sourceLocationCode}
                    error={form.isDirty("sourceLocationCode") ? "저장 필요" : ""}
                />
                <Update.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="수주일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                    error={form.isDirty("orderDate") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("orderDate") ? "red !important" : "",
                            borderColor: form.isDirty("orderDate") ? "red !important" : "",
                        },
                        error: { fontSize: 11, color: "red" }
                    }}
                />
                <Update.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납기일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                    error={form.isDirty("deliveryDeadline") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("deliveryDeadline") ? "red !important" : "",
                            borderColor: form.isDirty("deliveryDeadline") ? "red !important" : "",
                        },
                        error: { fontSize: 11, color: "red" }
                    }}
                />
                <Update.ButtonBox>
                    <Update.Button color="gray" onClick={closeModal}>취소</Update.Button>
                    <Update.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrderItems",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        수주 항목 수정
                    </Update.Button>
                </Update.ButtonBox>
            </Update.Wrapper>
        </>
    )
};