
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import { setToLocaleString } from "@/utils/unitMark";
import { Avatar, Button, Checkbox, Flex, Text, Tooltip } from "@mantine/core";
import { WipInventoriesGet200Response, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconFilterPlus, IconRecycle } from "@tabler/icons-react";
import { Column, SelectColumn } from "react-data-grid";

// 우리회사에서 다루는 재공품을 관리합니다.
export const WipInventoryPage = () => {
    const { openModal, closeModal } = useModal();
    const { resetQueryStrings } = useStandardLayout();

    const columns: readonly Column<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner>[] = [
        {
            ...SelectColumn,
            resizable: true,
        },
        {
            key: "itemCode",
            name: "품목코드",
            sortable: true,
            resizable: true,
            formatter: ({ row }: { row: WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner }) => {
                return (<LinkAnchor onClick={() => openModal(<ItemsDetailForm itemCode={row.itemCode} />, null, "")}>{row.itemCode}</LinkAnchor >);
            },
        },
        {
            key: "locationCode",
            name: "위치",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <LinkAnchor onClick={() => openModal(<LocationDetailForm locationCode={row.locationCode ?? ""} />, null, "로케이션 상세")}>{row.locationCode}</LinkAnchor>
                );
            },
        },
        {
            key: "productionPlanId",
            name: "생산 계획 번호",
            sortable: true,
            resizable: true,
            formatter: (row) => {
                return (
                    <Flex h="100%" justify="flex-end">
                        <LinkAnchor onClick={() => openModal(<ProductionPlanViewForm ProductionPlanId={row.row.productionPlanId} />, null, "")}>{row.row.productionPlanId}</LinkAnchor>
                    </Flex>
                );
            },
        },
        {
            key: "routingStep",
            name: "재공 라우팅단계",
            width: 800,
            formatter: (row) => {
                if (!row.row?.routingStep || typeof row !== "object") {
                    return null;
                }

                const routingSteps = Object.entries(row.row.routingStep);

                return (
                    <Flex w="100%" h="100%" align="center" gap="xs">
                        {routingSteps.map(([step, checked], index) => {
                            const routing = row.row.productionPlans?.routingsData?.[index];
                            const operationName = routing?.operation?.name ?? "";

                            return (
                                <Checkbox
                                    key={step}
                                    value={step}
                                    label={`(${routing?.seq}) ${operationName ? operationName : ""} 라우팅`}
                                    disabled
                                    checked={Boolean(checked)}
                                />
                            );
                        })}
                    </Flex>
                );
            },
        },
        {
            key: "quantity",
            name: "재고 수량",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <Flex justify="flex-end">
                        {setToLocaleString(row.quantity)} {row.unitText}
                    </Flex>
                );
            },
        },
    ];

    return (
        <Flex w="100%" h="100%" gap="xs" direction="column">
            <Flex p="sm" justify="flex-end" rowGap="md">
                <Button
                    rightIcon={<IconRecycle />}
                    color="teal"
                    onClick={() => resetQueryStrings()}
                >
                    검색값 초기화
                </Button>
            </Flex>
            <Flex bg="white" px="sm" direction="column" gap="xs">
                <Text fz="xl" fw={600}>재공품 리스트</Text>
                <Text fz="xs">우리 회사에서 다루는 재공품을 관리합니다.</Text>
            </Flex>
            <Flex justify="space-between" align="flex-start" px="sm">
                <Flex wrap="wrap" styles={flexGrowable}>
                    <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
                        <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
                            <Avatar color="blue" variant="outline">
                                <IconFilterPlus />
                            </Avatar>
                        </Tooltip>
                        <Flex direction="column" gap="xs" wrap="wrap">
                            <Filter />
                            <Sorter />
                        </Flex>
                    </Flex>
                </Flex>
                <Flex styles={flexStable}>
                    <SearchBox />
                </Flex>
            </Flex>
            <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
                <StandardLayoutTableWrapper>
                    <ListTable<WipInventoriesGet200Response, WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner> columns={columns} />
                </StandardLayoutTableWrapper>
                <PaginationBox<WipInventoriesGet200Response> />
            </Flex>
        </Flex>
    );
};