import { equipments } from "@/api/equipment/useEquipmentQuery";
import { MonitoringBox } from "@/features/monitoring/components/parentsBox/Monitoring";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { Main } from "@/features/standard/Main";
import { BASE_URL } from "@/instance/axios";
import { getLowerFields, getTextFields, getUpperFields } from "@/utils/monitoringFieldUtils";
import { MultiSelect } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSocket } from "socket.io-react-hook";


export type MonitoringDataProps = {
    denominatorLoad?: number
    equipmentCode?: string
    equipmentName?: string
    errorName?: string
    errorNumber?: number
    idleTime?: string
    idleTimeCreatedAt?: Date
    influxEquipmentCode?: string
    itemCode?: string
    itemName?: string
    itemSpec?: string
    keyCamStatus?: string
    keyCamStatusNumber?: number
    limitCounter?: number
    loadRatio?: number | undefined
    moldCavity?: number | undefined
    moldCode?: string
    msPerUnit?: number
    numeratorLoad?: number
    presetCounter?: number
    pressStatus?: string
    pressStatusNumber?: number | undefined
    remainTime?: string
    runTime?: string
    runTimeCreatedAt?: Date
    runTimeRatio?: number
    spm?: number
    totalCounter?: number
    totalTon?: number
    worker?: string
    equipmentType?: 'FORMING' | 'PACKING' | 'FORGING';
    mainFeederRpm?: number;
    mainScrewRpm?: number;
    sideFeederRpm?: number;
    sideScrewRpm?: number;
    vacuumRpm?: number;
    vacuumPressure?: number;
    resinTemperature?: number;
    resinPressure?: number;
    packingCounter?: number;
    lotId?: number;
    quantity?: string;
    targetQuantity?: string;
    kgPerCount?: string;
    totalPackingCounter?: string;
    workingTime?: string;
}


const initialMonitoringData: MonitoringDataProps = {
    "denominatorLoad": 0,
    "equipmentCode": "OP05",
    "equipmentName": "OP05",
    "errorName": "-",
    "errorNumber": 0,
    "idleTime": "02:04:45",
    // "idleTimeCreatedAt": "2024-10-30T02:36:27.824098359Z",
    "influxEquipmentCode": "P0200092",
    "keyCamStatus": "연속",
    "keyCamStatusNumber": 3,
    "limitCounter": 3000,
    "loadRatio": 0,
    "moldCavity": 0,
    "msPerUnit": 1859.93,
    "numeratorLoad": 0,
    "presetCounter": 778,
    "pressStatus": "동작 중",
    "pressStatusNumber": 1,
    "remainTime": "01:07:08",
    "runTime": "01:10:22",
    // "runTimeCreatedAt": "2024-10-30T02:41:40.394123701Z",
    "runTimeRatio": 36.06389339711284,
    "spm": 32,
    "totalCounter": 21187,
    "totalTon": 0,
    "itemName": 'BRKT ENG MTG RR(중)',
    // 'itemCode': 400003
};

const Monitoring = Object.assign({}, Main, { Parents });

const useSocketListeners = (
    equipmentCodes: string[] | undefined,
    socket: any,
    setMonitoringData: React.Dispatch<React.SetStateAction<MonitoringDataProps[]>>
) => {
    useEffect(() => {
        if (!equipmentCodes) return;

        equipmentCodes.forEach((code, index) => {
            const roomName = `PM_${code}`;
            socket.on(roomName, (message: MonitoringDataProps) => {
                setMonitoringData((prevData) => {
                    const newData = [...prevData];
                    newData[index] = message;
                    return newData;
                });
            });
            socket.emit("call", "socket.join", { room: roomName }, (err: any) => {
                if (err) console.error(err);
            });
        });

        return () => {
            equipmentCodes.forEach((code) => {
                const roomName = `PM_${code}`;
                socket.off(roomName);
            });
        };
    }, [socket, equipmentCodes, setMonitoringData]);
};

export const MonitoringTable = () => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [multiSelectData, setMultiSelectData] = useState<{ value: string; label: string; }[]>([]);
    const [monitoringData, setMonitoringData] = useState<MonitoringDataProps[]>([]);
    const [dummyData, setDummyData] = useState(initialMonitoringData);
    const { socket } = useSocket(BASE_URL, { autoConnect: true });

    const { data: equipmentData } = useQuery({
        ...equipments?.find({
            query: {
                $and: [
                    {
                        influxEquipmentCode: {
                            $and: [
                                { $not: null },
                                { $not: '' },
                                { $or: selectedOptions ? selectedOptions.map(option => ({ $eq: option })) : [] }
                            ],
                        },
                    },
                ],
            },
            sort: "seq, id",
            populate: ['location', "works"],
        })
    });

    const equipmentCodes = equipmentData?.data?.map((data) => data?.code);

    useSocketListeners(equipmentCodes, socket, setMonitoringData);

    useEffect(() => {
        if (multiSelectData.length === 0 && equipmentData) {
            setMultiSelectData(
                equipmentData?.data?.map((data) => ({
                    value: data.influxEquipmentCode as string,
                    label: data.name,
                })) || []
            );
        }
    }, [equipmentData]);

    const handleMultiSelectChange = (values: string[]) => {
        setSelectedOptions(values);
        setMonitoringData([]);
    };

    useEffect(() => {
        const updateData = () => {
            setDummyData(prevData => {
                const newSpm = Math.floor(Math.random() * (40 - 30 + 1)) + 35;
                const newPresetCounter = Math.min((prevData?.presetCounter as number) + 1, (prevData?.limitCounter as number));
                const newTotalCounter = (prevData?.totalCounter as number) + 1;

                let [runHours, runMinutes, runSeconds] = prevData?.runTime?.split(':').map(Number) ?? [0, 0, 0];
                let idleTimeIncrease = 0; // 비가동시간 증가 변수

                // 상태에 따라 시간 증가 로직
                if (prevData.pressStatus === "동작 중") {
                    runSeconds += 1; // 가동시간 증가
                    if (runSeconds === 60) {
                        runSeconds = 0;
                        runMinutes += 1;
                    }
                    if (runMinutes === 60) {
                        runMinutes = 0;
                        runHours += 1;
                    }
                } else if (prevData.pressStatus === "대기 중") {
                    idleTimeIncrease = 1; // 대기 중일 때 비가동시간 증가
                }

                const newRunTime = `${String(runHours).padStart(2, '0')}:${String(runMinutes).padStart(2, '0')}:${String(runSeconds).padStart(2, '0')}`;

                let [idleHours, idleMinutes, idleSeconds] = prevData?.idleTime?.split(':').map(Number) ?? [0, 0, 0];
                idleSeconds += idleTimeIncrease; // 비가동시간 증가
                if (idleSeconds === 60) {
                    idleSeconds = 0;
                    idleMinutes += 1;
                }
                if (idleMinutes === 60) {
                    idleMinutes = 0;
                    idleHours += 1;
                }
                const newIdleTime = `${String(idleHours).padStart(2, '0')}:${String(idleMinutes).padStart(2, '0')}:${String(idleSeconds).padStart(2, '0')}`;

                const totalIdleSeconds = idleHours * 3600 + idleMinutes * 60 + idleSeconds;
                const totalRunSeconds = runHours * 3600 + runMinutes * 60 + runSeconds;
                const newRunTimeRatio = totalIdleSeconds > 0 ? ((totalIdleSeconds - totalRunSeconds) / totalIdleSeconds) * 100 : 0;

                let [remainHours, remainMinutes, remainSeconds] = prevData?.remainTime?.split(':').map(Number) ?? [0, 0, 0];
                if (remainHours > 0 || remainMinutes > 0 || remainSeconds > 0) {
                    remainSeconds -= 1;
                    if (remainSeconds < 0) {
                        remainSeconds = 59;
                        remainMinutes -= 1;
                    }
                    if (remainMinutes < 0) {
                        remainMinutes = 59;
                        remainHours -= 1;
                    }
                }
                const newRemainTime = `${String(remainHours).padStart(2, '0')}:${String(remainMinutes).padStart(2, '0')}:${String(remainSeconds).padStart(2, '0')}`;

                // 80% 확률로 "동작 중", 20% 확률로 "대기 중"
                const randomStatus = Math.random() < 0.8 ? "동작 중" : "대기 중";

                return {
                    ...prevData,
                    spm: newSpm,
                    presetCounter: newPresetCounter,
                    totalCounter: newTotalCounter,
                    runTime: newRunTime,
                    idleTime: newIdleTime,
                    runTimeRatio: newRunTimeRatio,
                    remainTime: newRemainTime,
                    pressStatus: randomStatus // 랜덤 상태 변경
                };
            });

            // 3초 ~ 6초 사이의 랜덤한 시간 간격으로 다음 업데이트 설정
            const randomInterval = Math.floor(Math.random() * (10000 - 3000 + 1)) + 3000;
            setTimeout(updateData, randomInterval);
        };

        const randomInitialInterval = Math.floor(Math.random() * (10000 - 3000 + 1)) + 3000;
        const initialTimeout = setTimeout(updateData, randomInitialInterval);

        return () => clearTimeout(initialTimeout);
    }, []);


    return (
        <>
            <MultiSelect
                data={multiSelectData ?? []}
                placeholder="검색할 기계를 선택해주세요."
                onChange={handleMultiSelectChange}
            />
            <Monitoring.Parents>
                {[dummyData].map((data, index) => (
                    <MonitoringBox
                        key={index}
                        statusInfo={{ pressStatus: data?.pressStatus, errorName: data?.errorName }}
                        textFields={getTextFields(data)}
                        upperFields={getUpperFields(data)}
                        lowerFields={getLowerFields(data)}
                    />
                ))}
            </Monitoring.Parents>
        </>
    );


};

